import CenterContainer from "./CenterContainer";

export default function ImgBanner(props){
    const imgBannerStyle = {
        backgroundImage: `url(${props.imgSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        width: "100%",
        height: `${props.height}`,
        borderRadius: "10px"
    }
    return(
        <div>
            <CenterContainer width="90%">
                <div className="img-banner" style={imgBannerStyle}></div>
            </CenterContainer>
        </div>
    )
}