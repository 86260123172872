export default function Pill(props){
    const pillImgStyle = {
        backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)), url(${props.imgSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center"
    }
    return(
        <div className="pill">
            <div className="pill-img" style={pillImgStyle}></div>
            <div className="pill-text-container">
                <div className="pill-text">
                    <h1 className="pill-text-header">{props.title}</h1>
                    <br />
                    <p className="pill-description">{props.description}</p>
                    <br />
                    <a href="https://calendar.app.google/pRCJE6cRmdCiVL14A" target="_blank" rel="noopener noreferrer"><button className='action-medium'>{props.buttonText}</button></a>
                </div>
            </div>
        </div>
    )
}