import { Children } from 'react';

export default function WrapCol(props) {
    return (
        <div className='wrap-col'>
            {Children.map(props.children, child =>
                <div className='wrap-col-child'>
                    {child}
                </div>
            )}
        </div>
    )
}