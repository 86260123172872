import { useState } from "react";

export default function Faq(props) {
    const [faqActive, setFaqActive] = useState(false);
    const activeFaqStyle = {
        borderLeft: "5px solid #0066ff"
    }
    return (
        <div className="faq" style={faqActive ? activeFaqStyle : null} onClick={() => setFaqActive(!faqActive)}>
            <div className="faq-top-line">
                <h3 className="faq-title">{props.question}</h3>
                {faqActive ? <button className="faq-button faq-active">—</button>
                    : <button className="faq-button faq-inactive">+</button>}
            </div>
            {faqActive ? <p className="faq-answer">{props.answer}</p> : null}
        </div>
    )
}