import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import VariableSpacer from './VariableSpacer';

export default function ContactForm() {
    const [state, handleSubmit] = useForm("xoqornkd");
    if (state.succeeded) {
        return <h1 style={{height:"80vh", textAlign:"center", width: "100%", display:"flex", justifyContent:"center", alignItems:"center"}}>Submitted Successfully!</h1>;
    }
    return (
        <form onSubmit={handleSubmit} className='contact-form'>
            <h2 className="contact-title">Contact Us</h2>
            <br />
            <input
                required
                id="name"
                type="name"
                name="name"
                placeholder='Name'
            />
            <VariableSpacer height="2vh" />
            <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
            />
            <input
                required
                id="email"
                type="email"
                name="email"
                placeholder='Email'
            />
            <VariableSpacer height="2vh" />
            <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
            />
            <textarea
                required
                id="message"
                name="message"
                placeholder='Message'
            />
            <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
            />
            <VariableSpacer height="2vh" />
            <button type="submit" className="action action-medium" disabled={state.submitting}>
                Submit
            </button>
        </form>
    );
}