import handleViewport from 'react-in-viewport';

import ImgBanner from "../components/ImgBanner";
import ImgLanding from '../components/ImgLanding';
import VariableSpacer from "../components/VariableSpacer";
import Card from '../components/Card';
import WrapCol from '../components/WrapCol';
import CenterContainer from '../components/CenterContainer';
import Pill from '../components/Pill';
import Faq from '../components/Faq';
import ContactForm from '../components/ContactForm';

import LandingResults from "../assets/dark-setup.jpg";
import MacHue from '../assets/mac-hue.jpg';
import Frontend from '../assets/frontend.png';
import Backend from '../assets/backend.png';
import Cloud from '../assets/cloud.png';
import WorkingMan from '../assets/working-man.jpg';
import Footer from '../components/Footer';

import Tools from "../assets/tools.png";
import DataMgmt from "../assets/data-management.png";


const HomeSection = (props) => {
    const { inViewport, forwardedRef, enterCount } = props;
    let fadeClass = "";
    if (inViewport && enterCount === 1) {
        fadeClass = "fade-in";
    }
    return (
        <div className='home-section' ref={forwardedRef}>
            <div className='landing-center'>
                <div className='landing-center-inner'>
                    <div className={`${fadeClass} center-text`}>
                        <h1 className='site-title'>Sites that Sell<span className='blue-dot'>.</span></h1>
                        <h1 className='site-subtitle italics'>We build <img src={Tools} alt="build" className='inline-img' />and manage <img src={DataMgmt} alt="manage" className='inline-img' />websites for small businesses and new entrepreneurs.</h1>
                        <br></br>
                        <h1 className='site-subtitle-2'>Book a Free Strategy Call Today</h1>
                        <br></br>
                        <a href="https://calendar.app.google/pRCJE6cRmdCiVL14A" target="_blank" rel="noopener noreferrer"><button className='action-medium hue'>Book Now<span style={{ paddingLeft: "7px" }}>→</span></button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AboutSection = (props) => {
    const { inViewport, forwardedRef, enterCount } = props;
    let fadeClass = "";
    if (inViewport && enterCount === 1) {
        fadeClass = "fade-in";
    }
    return (
        <div className="about-section" ref={forwardedRef}>
            <CenterContainer>
                <div className={`${fadeClass}`}>
                    <h1 className="about-title">ABOUT US</h1>
                    <VariableSpacer height="2vh" />
                    <div className={`about-text ${fadeClass}`}>
                        <div className="about-description">
                            <p>At Untgl, we're on a mission to revolutionize web development. Our passion lies in making the complex simple, delivering results, and ensuring high-quality web solutions for our clients.</p>
                            <p>
                                Web development has traditionally been a complicated and time-consuming process, requiring collaboration between frontend and backend developers, cloud service setup, and continuous integration and deployment efforts.</p>
                        </div>
                        <div className="about-description">
                            <p>This complexity often acts as a barrier, preventing non-technical users and businesses from taking full advantage of the online opportunities available.</p>
                            <p>
                                Untgl aims to simplify the process, by taking all the steps behind the scenes. As an agency, we hire top designers and developers to craft the best digital experiences that ultimately generate results for our clients.</p>
                        </div>
                    </div>
                </div>
            </CenterContainer>
        </div>
    )
}

const ServiceSection = (props) => {
    const { inViewport, forwardedRef, enterCount } = props;
    let fadeClass = "";
    if (inViewport && enterCount === 1) {
        fadeClass = "fade-in";
    }
    return (
        <div className='service-cards' ref={forwardedRef}>
            <WrapCol className={fadeClass}>
                <Card title="Frontend" width="45%" imgSrc={Frontend} >
                    <p><span className='card-text-header'>Crafting Engaging User Experiences.</span> Untgl excels in crafting captivating, user-friendly websites and apps using modern technology. We focus on UI design, responsive solutions, and user-friendly navigation for engaging experiences.</p>
                </Card>
                <Card title="Backend" width="45%" imgSrc={Backend} >
                    <p><span className='card-text-header'>Building Reliable Foundations.</span> Our backend services create robust foundations for data-driven web apps. Our experts manage databases, server-side scripting, and RESTful APIs, ensuring seamless frontend-backend communication.</p>
                </Card>
                <Card title="Cloud Integration" width="45%" imgSrc={Cloud} >
                    <p><span className='card-text-header'>Seamless Scaling and Efficiency.</span> We simplify cloud integration, optimizing scalability and performance. Our experts configure resources, enhance security, and bridge the gap between web development and the cloud.</p>
                </Card>
            </WrapCol>
        </div>
    )
}

const FaqSection = (props) => {
    const { inViewport, forwardedRef, enterCount } = props;
    let fadeClass = "";
    if (inViewport && enterCount === 1) {
        fadeClass = "fade-in";
    }
    return (
        <div className="faq-section" ref={forwardedRef}>
            <CenterContainer>
                <h1 className="faq-section-title">Frequently Asked Questions</h1>
                <div className={`faq-inner-container ${fadeClass}`}>
                    <div className={`faq-list ${fadeClass}`}>
                        <br />
                        <Faq question="Why Choose Untgl Over a Large Web Agency?" answer="Untgl offers a personalized, client-centric approach. We're agile, responsive, and committed to delivering tailored solutions. Our close-knit team ensures your project receives individualized attention and expertise." />
                        <Faq question="Why Choose Untgl Over an Online Site Builder?" answer="Untgl offers custom web development, ensuring your site is unique and optimized for your specific needs. Site builders are limited in flexibility and may not meet your complex requirements." />
                        <Faq question="Why Not Hire My Own Web Development Team?" answer="Hiring an in-house team can be costly and time-consuming. Untgl provides experienced experts, ready to start your project without the overhead of recruitment and management." />
                        <Faq question="What Industries Does Untgl Serve?" answer="Untgl serves a diverse range of industries, including e-commerce, healthcare, finance, education, and more. Our versatility and expertise make us adaptable to your sector's unique needs." />
                        <Faq question="How Does Untgl Ensure Website Security?" answer="Security is paramount at Untgl. We conduct security audits, use SSL encryption, follow best practices, and stay up-to-date with the latest security measures to protect your website." />
                        <Faq question="Can Untgl Help with SEO and Digital Marketing?" answer="Yes, we offer SEO services to enhance your online visibility. Our team can provide digital marketing strategies to drive traffic and boost your online presence." />
                        <Faq question="What Sets Untgl Apart from Competitors?" answer="Our focus on simplicity, innovation, and quality sets us apart. We create custom solutions, ensure transparent communication, and prioritize your success above all." />
                        <Faq question="How Do I Get Started with Untgl?" answer="Getting started is easy. Contact us via our website, share your project details, and our team will be in touch to discuss your needs, goals, and how we can help." />
                        <Faq question="Does Untgl Offer Ongoing Support and Maintenance?" answer="Yes, we provide ongoing maintenance and support to keep your web solutions running smoothly. Our experts are available to address issues, provide updates, and ensure your web presence remains optimal." />
                    </div>
                    <div className="faq-img"></div>
                </div>
            </CenterContainer>
        </div>
    )
}

const HomeViewport = handleViewport(HomeSection, /** options: {}, config: {} **/);
const AboutViewport = handleViewport(AboutSection, /** options: {}, config: {} **/);
const ServiceViewport = handleViewport(ServiceSection, /** options: {}, config: {} **/);
const FaqViewport = handleViewport(FaqSection, /** options: {}, config: {} **/);

export default function Home(props) {
    return (
        <section id="home">
            <div className='hue-bar'></div>
            <VariableSpacer height="8vh" />
            <HomeViewport onEnterViewport={() => console.log('home-enter')} onLeaveViewport={() => console.log('home-leave')} />
            <VariableSpacer height="8vh" />
            <ImgBanner imgSrc={LandingResults} height="80vh" />
            <div id="about"></div>
            <VariableSpacer />
            <AboutViewport onEnterViewport={() => console.log('about-enter')} onLeaveViewport={() => console.log('about-leave')} />
            <VariableSpacer />
            <div className="service-section" id="services">
                <ImgLanding title="The future of web development." subtitle="Everything you need to generate sales, and more." imgSrc={MacHue} buttonText="Let's Go" />
                <VariableSpacer />
                <CenterContainer>
                    <h1 className="service-section-title">SERVICES</h1>
                    <br />
                    <h1 className="service-section-subtitle">We tailors web solutions to meet your unique needs, whether it's creating captivating frontend experiences, establishing robust backends, or achieving seamless cloud integration.</h1>
                    <br />
                    <VariableSpacer />
                    <ServiceViewport onEnterViewport={() => console.log('service-enter')} onLeaveViewport={() => console.log('service-leave')} />
                    <Pill title="Comprehensive Services to Elevate Your Digital Presence" description="Untgl offers a range of additional services to enhance your online presence. Our expertise includes Search Engine Optimization (SEO), strategic consultation, maintenance and support, and analytics and reporting. From development to maintenance, we're here to ensure your digital success." imgSrc={WorkingMan} buttonText="Meet with Us" />
                    <VariableSpacer />
                </CenterContainer>
            </div >
            <VariableSpacer />
            <div id="faqs"></div>
            <VariableSpacer />
            <FaqViewport onEnterViewport={() => console.log('faq-enter')} onLeaveViewport={() => console.log('faq-leave')} />
            <VariableSpacer />
            <VariableSpacer />
            <CenterContainer>
                <div className='center-text'>
                    <h1 className='appt'>Book an Appointment</h1>
                    <br></br>
                    <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3KQhWklcq7fRr_iBgtqzg9VsfF0tQWoDnhxHQUFxTZjib9Juxyfuwf23TEBj1NISJUC6Pn2hrV?gv=true" style={{ border: "1px solid #999999", borderRadius: "10px" }} width="100%" height="700" frameborder="0" title="Book Appointment"></iframe>
                </div>
            </CenterContainer>
            <VariableSpacer />
            <VariableSpacer />
            <div id="contact"></div>
            <CenterContainer>
                <div className="contact-section">
                    <div className="contact-img"></div>
                    <ContactForm />
                </div>
            </CenterContainer>
            <VariableSpacer />
            <Footer />
        </section>
    )
}