import VariableSpacer from "./VariableSpacer";

export default function Card(props) {
    const cardStyle = {
        backgroundColor: `${props.bgColor ? props.bgColor : "#111111"}`,
        color: `${props.color ? props.color : "#ffffff"}`,
        height: "45vh",
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative"
    }

    const cardImgStyle = {
        backgroundImage: `url(${props.imgSrc})`,
        backgroundSize: "250%",
        backgroundRepeat: "no-repeat",
        width: "85%",
        height: "65%",
        position: "absolute",
        bottom: "0",
        right: "0"
    }

    return (
        <div className="card">
            <div style={cardStyle}>
                <div className="card-inner-container">
                    <h1 className="card-title">{props.title}</h1>
                    <div className="card-img-container">
                        <div style={cardImgStyle}></div>
                    </div>
                </div>
            </div>
            <VariableSpacer height="5vh" />
            <div className="card-text">{props.children}</div>
        </div>
    )
}