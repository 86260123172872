import CenterContainer from "./CenterContainer";
import VariableSpacer from "./VariableSpacer";

export default function ImgLanding(props) {
    const imgLandingStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${props.imgSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        width: "100%",
        height: "70vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    }
    return (
        <div style={imgLandingStyle} className="img-landing">
            <CenterContainer>
                <h1 className="video-title">{props.title}</h1>
                <h2 className="video-subtitle">{props.subtitle}</h2>
                <VariableSpacer height="3vh" />
                <a href="https://calendar.app.google/pRCJE6cRmdCiVL14A" target="_blank" rel="noopener noreferrer"><button className='action-medium'>{props.buttonText}</button></a>
            </CenterContainer>
        </div>
    )
}