import CenterContainer from "./CenterContainer";

export default function Footer() {
    return (
        <div className="footer">
            <CenterContainer>
                <h1>Untangle Official Site</h1>
                <br />
                <p>Untangle Ⓒ 2023 | All Rights Reserved</p>
                <p>This site was built using React.js</p>
                <a className="a-link" href="https://unsplash.com/collections/fbW8-gQ7CJo/my-first-collection" target="_blank" rel="noreferrer">Image Sources</a>
                <br></br>
                <a className="a-link" href="https://www.flaticon.com/free-icons/data-management" title="data-management icons" target="_blank" rel="noreferrer">Data-management icon - Flaticon</a>
                <br></br>
                <a className="a-link" href="https://www.flaticon.com/free-icons/electrical-service" title="electrical-service icons" target="_blank" rel="noreferrer">Electrical-service icon - Flaticon</a>
            </CenterContainer>
        </div>
    )
}